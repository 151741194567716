import Alpine from 'alpinejs'

export default (function() {
  Alpine.data('slider', function () {
    return {
      async init() {
        const [SwiperModule] = await Promise.all([
          import('swiper'),
          import('swiper/css')
        ])
        const { default: Swiper, Autoplay, Pagination } = SwiperModule

        Swiper.use([Autoplay, Pagination])
        const slider = new Swiper('.swiper', {
          autoplay: {
            delay: 5000,
          },
          pagination: {
            el: '.swiper-pagination',
            type: 'custom',
            clickable: true,
            renderCustom(slider, current, total) {
              const items = Array
                .from({ length: total })
                .map((_, i) => `<li class="swiper-pagination-bullet m-px"><button class="h-1 w-16 lg:w-1 lg:h-16 ${i === current - 1 ? 'bg-blue-gray-dark' : 'bg-blue-gray'}"></button></li>`)
              return `<ul class="flex lg:flex-col mx-auto">${items.join('')}</li>`
            },
          },
          //direction: 'vertical',
        })

        slider.on('slideChange', (slider) => {
          this.$dispatch('slide-change', slider)
        })
      }
    }
  })
})()
import Alpine from 'alpinejs'

export default (function () {
  Alpine.data('select', function(items = [], id = 'dropdown') {
    const input = this.$refs['input']
    return {
      id,
      items,
      active: null,
      open: false,
      activate(i, triggerEvent = true) {
        this.active = i
        if (input) {
          input.options[i + 1].selected = 'selected'
          //input.value = input.options[i + 1].value
          if (triggerEvent) {
            const e = new Event('change', { bubbles: true })
            input.dispatchEvent(e);
          }
        }
      },
      toggle() {
        this.open = !this.open
      },
      close() {
        this.open = false
      },
      reset() {
        this.active = null
      },
      isActive() {
        return this.active !== null
      },
      getId(suffix) {
        return id + '-' + suffix
      },
      labelBindings: {
        ['@click.prevent']() { this.toggle() },
        ['@click.outside']() { this.close() },
        [':aria-expanded']() { return this.open ? 'true' : 'false' },
        [':aria-owns']() { return this.open && this.getId('menu') },
      },
      menuBindings: {
        [':id']() { return this.getId('menu') },
        [':aria-labelledbyid']() { return this.getId('label') },
      },
    }
  })
})()

import Alpine from 'alpinejs'

export default (function() {
  Alpine.data('stories', function () {
    return {
      controller: null,
      async init() {
        const [Zuck] = await Promise.all([
          import('zuck.js').then(mod => mod.default),
          import('../../styles/components/stories.scss'),
        ])
        this.controller = new Zuck(this.$el, {
          skin: 'snapgram',
          backNative: false,
          previousTap: true,
          avatars: true,
          list: true,
          openEffect: true,
          autoFullScreen: false,
          cubeEffect: false,
          paginationArrows: true,
          reactive: true,
          language: {
            unmute: 'Hier Stummschaltung aufheben',
            keyboardTip: 'Zum Weiterblättern Leertaste drücken',
            visitLink: 'Link besuchen',
          },
          template: {
            timelineItem: (item) => {
              return `
              <div class="story w-full lg:w-1/6 px-6 pb-6" data-id="${item.id}" data-photo="${item.photo}" data-last-updated="${item.lastUpdatedAgo}">
                <figure class="text-center">
                    <div class="bubble-mask w-40 mx-auto">
                        <a href="#" class="item-preview item-link"><img src="${item.photo}" alt="" class="relative w-full"></a>
                    </div>
                    <ul class="items hidden"></ul>
                    <figcaption class="pt-4 leading-tight">
                        <h3 class="text-18 lg:text-20 text-blue-dark"><a href="#" class="name">${item.title}</a></h3>
                        <div class="subline text-14 lg:text-16 text-blue-base">${item.subline}</div>
                    </figcaption>
                </figure>
              </div>
              `
            },
            viewerItem: (item) => {
              return `
                <div class="story-viewer">
                  <div class="head">
                    <div class="left">
                      <span class="item-preview">
                        <img lazy="eager" class="profilePhoto" src="${item.photo}" />
                      </span>
                      <div class="info">
                        <strong class="name">${item.name}</strong>
                        <span class="subline">${item.subline}</span>
                      </div>
                    </div>
                    <div class="right">
                      <span class="loading"></span>
                      <a class="close" tabIndex="2">&times;</a>
                    </div>
                  </div>
                  <div class="slides-pointers">
                    <div class="wrap"></div>
                  </div>
                  <div class="slides-pagination">
                    <span class="previous">&lsaquo;</span>
                    <span class="next">&rsaquo;</span>
                  </div>
                </div>
              `
            },
          },
        })


        const parseItems = (story, forceUpdate) => {
          const storyId = story.getAttribute('data-id')
          const storyItems = this.$el.querySelectorAll(
            `[data-id="${storyId}"] .items > li`
          )
          const items = []

          if (forceUpdate) {
            ;[...storyItems].map(({ firstElementChild }) => {
              const a = firstElementChild
              const img = a.firstElementChild

              const item = {
                id: a.getAttribute('data-id'),
                src: a.getAttribute('href'),
                length: a.getAttribute('data-length'),
                type: a.getAttribute('data-type'),
                time: a.getAttribute('data-time'),
                link: a.getAttribute('data-link'),
                linkText: a.getAttribute('data-linkText'),
                preview: img.getAttribute('src'),
              }
              // collect all attributes
              const all = a.attributes
              // exclude the reserved options
              const reserved = [
                'data-id',
                'href',
                'data-length',
                'data-type',
                'data-time',
                'data-link',
                'data-linktext',
              ]
              for (let z = 0; z < all.length; z++) {
                if (reserved.indexOf(all[z].nodeName) === -1) {
                  item[all[z].nodeName.replace('data-', '')] = all[z].nodeValue
                }
              }

              // destruct the remaining attributes as options
              items.push(item)
            })

            this.controller.data[storyId].title = story.querySelector('.name')?.innerText
            this.controller.data[storyId].subline = story.querySelector('.subline')?.innerText
            this.controller.data[storyId].items = items
            if (items.length) this.controller.data[storyId].noItems = false
          }
        }

        [...this.$el.querySelectorAll('.story')].map(item => {
          parseItems(item, true)
        })
      }
    }
  })
})()
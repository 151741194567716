import Alpine from 'alpinejs'

export default (function () {
  Alpine.store('app', {
    scrolled: false,
    headerOffset: 0,
    masthead: {
      height: 0
    },
    syncHeight(key, el) {
      this[key].height = el.offsetHeight
  
      try {
        new ResizeObserver(([entry]) => {
          const newHeight = entry.contentRect.height
          if (this[key].height !== newHeight) this[key].height = newHeight
        }).observe(el)
      } catch (error) {
        // no resize observer possible
      }
    },
    syncOffset(key, el) {
      this[key] = el.offsetTop + el.offsetHeight

      try {
        new ResizeObserver(() => {
          const newOffset = el.offsetTop + el.offsetHeight
          if (this[key] !== newOffset) this[key] = newOffset
        }).observe(document.body)
      } catch (error) {
        // no resize observer possible
      }
    },
    track(...args) {
      if (!window._paq || typeof window._paq !== 'object') return
      window._paq.push(['trackEvent', ...args])
    },
    init() {
      const isScrolledOverThreshold = () => {
        return window.scrollY > (this.masthead.height || 100)
      }
      this.scrolled = isScrolledOverThreshold()
      window.addEventListener('scroll', () => {
        if (this.scrolled !== isScrolledOverThreshold()) this.scrolled = isScrolledOverThreshold()
      }, true)
    },
  })
})()

import Alpine from 'alpinejs'
import 'modules/bootstrap'
import 'modules/scrollSpy'
import 'modules/slider'
import 'modules/stories'
import 'modules/lightbox'
import 'modules/accordion'
import 'modules/select'
import 'modules/modal'
import 'modules/form'

Alpine.data('tabs', () => ({
  active: 0,
  activate(i) {
    this.active = i
  },
}))

window.Alpine = Alpine
Alpine.start()

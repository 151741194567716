import Alpine from 'alpinejs'

export default (function () {
  Alpine.data('accordion', function (initial = false) {
    const getHeight = () => this.$refs.child.scrollHeight
  
    return {
      open: initial,
      allowOverflow: initial,
      height: getHeight(),
      toggle() {
        this.open = !this.open
      },
      init() {
        // listen to size changes
        try {
          new ResizeObserver(() => {
            const newHeight = getHeight()
            if (this.height !== newHeight) this.height = newHeight
          }).observe(this.$refs.child)
        } catch (error) {
          // no resize observer possible
        }
      }
    }
  })
})()

import Alpine from 'alpinejs'
import { dispatch } from 'helpers/dom'

const scrollTo = (destination) => {
  if (!destination) return

  window.scrollTo({
    top:
      destination.getBoundingClientRect().top +
      document.documentElement.scrollTop -
      (Alpine.store('app').masthead.height || 0),
    behavior: 'smooth',
  })
}

const setHash = (hash) => {
  history.pushState({}, '', hash)
  dispatch('programmatichashchange')
}

export default (function () {
  let currentHash = window.location.hash
  let currentAnchor = currentHash?.substr(1)?.length
    ? document.getElementById(currentHash.substr(1))
    : null
  const scrollToHandler = (e) => {
    const { target } = e
    const currentTarget = target.closest(
      `a[href*="#"]:not([data-scrollhandler-ignore])`
    )
    if (!currentTarget) return
    const { pathname, hash } = currentTarget
    if (window.location.pathname === pathname && hash?.substr(1)?.length) {
      currentHash = hash
      setHash(currentHash)
      e.preventDefault()
    }
  }
  const hashchangeHandler = () => {
    currentHash = window.location.hash
    currentAnchor = document.getElementById(currentHash.substr(1))
    scrollTo(currentAnchor)
  }

  Alpine.nextTick(() => { if (currentAnchor) scrollTo(currentAnchor)} )
  document.documentElement.addEventListener('click', scrollToHandler)
  window.addEventListener('hashchange', hashchangeHandler)
  window.addEventListener('programmatichashchange', hashchangeHandler)

  document.addEventListener('click', (e) => {
    const elem = e.target
    if (!elem.closest(`[data-url]`)) return
    const url = elem.closest(`[data-url]`).dataset.url
    if (url && url.length > 1) window.location.href = url
  })
})()
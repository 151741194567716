import Alpine from 'alpinejs'

export default (function () {
  Alpine.data('lightbox', function (rawItems = []) {
    let Lightbox

    return {
      instance: null,
      dataSource: rawItems.map((item) => {
        if (item.video) {
          const video = item.video
          const title = item.title || item.alt
          return {
            ...item,
            alt: title,
            type: 'video',
            html: `
              <div class="flex h-full${title ? ' pb-11' : ''}">
                <video class="max-h-full m-auto" controls playsinline><source src="${video}" type="video/mp4"></video>
              </div>
            `.trim(),
          }
        }
        return item
      }),
      openLightbox(index = 0) {
        if (!Lightbox) return
        this.instance.loadAndOpen(index)
      },
      async init() {
        ;[Lightbox] = await Promise.all([
          import('photoswipe/lightbox').then((mod) => mod.default),
          import('photoswipe/style.css'),
        ])

        this.instance = new Lightbox({
          dataSource: this.dataSource,
          pswpModule: () => import('photoswipe'),
        })

        this.instance.on('contentActivate', ({ content }) => {
          // content becomes active (the current slide)
          // can be default prevented
          const video = content.element?.querySelector('video')
          const initVideoPlayback = () => {
            const maybePromise = playVideo(video)
            if (isPromise(maybePromise)) {
              content.playbackPromise = maybePromise
                .then(() => {content.playbackPromise = null})
                .catch(e => console.warn('Error playing video in slide', content.index) || console.warn(e))
            }
          }
          const listener = () => {
            initVideoPlayback()
            video.removeEventListener('canplay', listener)
          }

          // sometime playback does not start
          // when calling play directly
          if (video.readyState === 4) initVideoPlayback()
          else video.addEventListener('canplay', listener)
        })

        this.instance.on('contentDeactivate', ({ content }) => {
          // content becomes active (the current slide)
          const video = content.element?.querySelector('video')
          if (content.playbackPromise) content.playbackPromise.then(() => resetVideo(video))
          else resetVideo(video)
        })

        this.instance.on('uiRegister', () => {
          this.instance.pswp.ui.registerElement({
            name: 'custom-caption',
            order: 9,
            isButton: false,
            appendTo: 'root',
            html: 'Caption text',
            onInit: (el) => {
              el.classList.add('py-2', 'px-6', 'absolute', 'left-0', 'bottom-0', 'w-full', 'text-white', 'text-center')
              this.instance.pswp.on('change', () => {
                el.innerHTML = this.instance.pswp.currSlide.data.title || ''
              })
            },
          })
        })

        this.instance.init()
      },
      destroy() {
        this.instance.destroy()
      }
    }
  })
})()

function isPromise(obj) {
  return typeof obj === 'object' && obj instanceof Promise
}

function videoIsPlaying(video) {
  return video.currentTime > 0 && !video.paused && !video.ended && video.readyState > 2
}

function playVideo(video) {
  if (!video || videoIsPlaying(video)) return
  return video.play()
}

function resetVideo(video) {
  if (!video || !videoIsPlaying(video)) return
  video.pause()
  video.currentTime = 0
}

import Alpine from 'alpinejs'
import { toggleOverflow } from 'helpers/manipulation'

const FETCH_CONFIG = {
  mode: 'cors',
  credentials: 'same-origin',
}

export default (function () {
  Alpine.data('modalManager', function () {
    return {
      title: null,
      image: null,
      content: null,
      loading: false,
      error: false,
      isOpen: false,
      getTitleMarkup() {
        return this.title ? `<div class="px-6 pb-8"><h2 class="text-30 lg:text-36 leading-none text-blue-dark">${this.title}</h2></div>` : ''
      },
      open(e = {}) {
        const {
          detail:
            { type, data } = { type: '', data: {} }
        } = e
        const { title, image } = data
        this.title = title
        this.image = image

        switch (type) {
          case 'url':
            const { url, query } = data
            if (url) {
              const path = new URL(url, window.location)
              path.searchParams.append('isAjax', 1)

              if (query)
                Object.keys(query).forEach(key => path.searchParams.append(key, query[key]))

              if (location.hostname === path.hostname && path.hostname.length) {
                this.loading = true
                fetch(path, FETCH_CONFIG)
                  .then(async (resp) => {
                    if (!resp.ok) throw new Error('Failed loading')
                    return resp.text()
                  })
                  .then((content) => {
                    this.loading = false
                    this.content = content
                  })
                  .catch((e) => {
                    console.log(e)
                    this.loading = false
                    this.error = true
                  })
              }
            }
            break;
        
          default:
            break;
        }

        this.isOpen = true
        toggleOverflow(false)
      },
      close() {
        this.isOpen = false
        this.loading = false
        this.error = false
        toggleOverflow(true)
        this.$nextTick(() => {
          this.content = null
          this.title = null
          this.image = null
        })
      },
    }
  })

  Alpine.data('modal', function ({ type, data = {} } = {}) {
    return {
      open(additionalData = {}) {
        this.$dispatch('modal-open', { type, data: { ...data, ...additionalData } })
      },
      close() {
        this.$dispatch('modal-close')
      },
    }
  })
})()
